import React, { FormEventHandler, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Checkbox,
  Container, Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Paper, StandardTextFieldProps,
  TextField,
  Typography,
} from '@material-ui/core';
import Logo from '../../images/logo-dark.png';
import { RegistrationFormType, useRegistrationRequest } from '../hooks/useRegistrationRequest';
import { SwitchBaseProps } from '@material-ui/core/internal/SwitchBase';
import { Alert } from '@material-ui/lab';

const useStyle = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    padding: theme.spacing(4),
    backgroundColor: '#fff',
    boxShadow: '0px 2px 4px 2px rgba(83, 83, 83, 0.16)',
  },
  title: {
    marginBottom: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  formControl: {
    marginBottom: '40px',
  },
  formControlCheck: {
    marginBottom: theme.spacing(5),
    marginTop: theme.spacing(0.8)
  },
  regBtn: {
    position: 'relative',
    backgroundColor: '#b2a77d',
    color: '#fff',
    display: 'block',
    padding: '12px',
    textAlign: 'center',
    borderRadius: 0,
    width: '100%',
    textTransform: 'unset',
    fontSize: '16px',
    border: 0,
    '&::after': {
      content: '""',
      display: 'block',
      border: '1px solid #ccc',
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: 'calc(100% - 15px)',
      height: 'calc(100% - 15px)',
      transform: 'translate(-50%, -50%)',
    },
    '&:hover': {
      backgroundColor: '#b2a77d',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      cursor: 'pointer',
    },
  },
  loginBackground: {
    width: '100%',
    height: '100%',
    background: 'url(/images/authbg.png) no-repeat center center/cover',
    position: 'fixed',
    top: 0,
    left: 0,
    opacity: .5,
    zIndex: -1,
  },
}));

const RegistrationPage = () => {
  const classes = useStyle();

  const [data, setData] = useState<RegistrationFormType & {sameAsBilling: boolean, newsletter: boolean}>({
    email: '',
    emailConfirm: '',
    contact: '',
    companyPhone: '',

    name: '',
    billingPostCode: '',
    billingCity: '',
    billingAddress: '',
    taxNumber: '',
    billingPhone: '',

    sameAsBilling: false,
    shippingPostCode: '',
    shippingCity: '',
    shippingAddress: '',
    shippingPhone: '',
    newsletter: false,
  });
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [termsErrorMessage, setTermsErrorMessage] = useState("");
  const registrationRequest = useRegistrationRequest();
  const [success, setSuccess] = useState<boolean | undefined>(undefined)
  const [disabled, setDisabled] = useState(false)

  const handleChange: StandardTextFieldProps['onChange'] = (e) => {
    setData(data => ({ ...data, [e.target.name]: e.target.value }));
  };

  const handleChangeCheckBox:  SwitchBaseProps['onChange'] = (e) => {
    setData(data => ({...data, [e.target.name]: e.target.checked}))
  }

  const onHandleSubmit: FormEventHandler = (e) => {
    e.preventDefault();
    if(!termsAccepted){
      setTermsErrorMessage("Az adatkezelési tájékoztató elfogadása kötelező!");
      return;
    }else{
      setTermsErrorMessage("");
    }
    setSuccess(undefined)
    setDisabled(true)
    const sameShipping = data.sameAsBilling
    registrationRequest({
      ...data,
      companyPhone: data.companyPhone === '' ? undefined : data.companyPhone,
      shippingPostCode: sameShipping ? data.billingPostCode : data.shippingPostCode,
      shippingCity: sameShipping ? data.billingCity : data.shippingCity,
      shippingAddress: sameShipping ? data.billingAddress : data.shippingAddress,
      shippingPhone: sameShipping ? data.billingPhone : data.shippingPhone,
    }).then(() => {
      setSuccess(true)
    }).catch((e) => {
      console.log(e);
      setSuccess(false)
    }).finally(() => {
      setDisabled(false)
    });
  };

  return (
    <div>
      <div className={classes.loginBackground}/>
      <Container maxWidth={success ? 'sm' : 'lg'} className={classes.root} component={Paper}>
        <form onSubmit={onHandleSubmit}>
          <Grid container spacing={3}>
            <Grid xs={12} item>
              <div className={classes.title}>
                <Typography variant="h4">Regisztráció</Typography>
                <img src={Logo} alt="Nettfront" />
              </div>
            </Grid>
            {
              success ?
                <Grid item xs={12} container justify={'center'}>
                  <Alert variant="filled" severity="success">
                    A regisztrációs kérelem sikeresen elküldve!
                  </Alert>
                </Grid> : <>
                  <Grid item xs={12} container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <FormControl className={classes.formControl} fullWidth>
                        <TextField fullWidth label="E-mail cím" required onChange={handleChange} name="email" value={data.email} />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl className={classes.formControl} fullWidth>
                        <TextField fullWidth label="E-mail cím megerősítése" required onChange={handleChange} name="emailConfirm"
                                   value={data.emailConfirm} />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl className={classes.formControl} fullWidth>
                        <TextField fullWidth label="Kapcsolattartó" required onChange={handleChange} name="contact"
                                   value={data.contact} />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl className={classes.formControl} fullWidth>
                        <TextField fullWidth label="Céges telefonszám (partnerek esetén)" name="companyPhone"
                                   onChange={handleChange} value={data.companyPhone} />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6} style={{ borderRight: '1px solid #E5E5E5;' }}>
                    <Typography variant="h5" style={{ marginBottom: '20px' }}>Számlázási adatok</Typography>
                    <FormControl className={classes.formControl} fullWidth>
                      <TextField fullWidth label="Számlázási név" required name="name" onChange={handleChange} value={data.name} />
                    </FormControl>
                    <FormControl className={classes.formControl} fullWidth>
                      <TextField fullWidth label="Számlázási cím irányítószáma" required name="billingPostCode"
                                 onChange={handleChange} value={data.billingPostCode} />
                    </FormControl>
                    <FormControl className={classes.formControl} fullWidth>
                      <TextField fullWidth label="Számlázási cím városa" required name="billingCity" onChange={handleChange}
                                 value={data.billingCity} />
                    </FormControl>
                    <FormControl className={classes.formControl} fullWidth>
                      <TextField fullWidth label="Számlázási cím (utca, házszám)" required name="billingAddress"
                                 onChange={handleChange} value={data.billingAddress} />
                    </FormControl>
                    <FormControl className={classes.formControl} fullWidth>
                      <TextField fullWidth label="Adószám (EU-s, ha van)" required onChange={handleChange} name="taxNumber"
                                 value={data.taxNumber} />
                    </FormControl>
                    <FormControl className={classes.formControl} fullWidth>
                      <TextField fullWidth label="Telefonszám vagy mobilszám" required onChange={handleChange} name="billingPhone"
                                 value={data.billingPhone} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="h5" style={{ marginBottom: '20px' }}>Szállítási adatok</Typography>
                    <FormControl className={classes.formControlCheck} fullWidth>
                      <FormControlLabel
                        control={<Checkbox checked={data.sameAsBilling} onChange={handleChangeCheckBox} name="sameAsBilling" />}
                        label="A szállítási adatok megegyeznek a számlázási adatokkal."
                      />
                    </FormControl>
                    <FormControl className={classes.formControl} fullWidth>
                      <TextField fullWidth label="Szállítási cím irányítószáma" required name="shippingPostCode"
                                 onChange={handleChange}
                                 value={data.sameAsBilling ? data.billingPostCode : data.shippingPostCode}
                                 disabled={data.sameAsBilling}
                      />
                    </FormControl>
                    <FormControl className={classes.formControl} fullWidth>
                      <TextField fullWidth label="Szállítási cím városa" required name="shippingCity" onChange={handleChange}
                                 value={data.sameAsBilling ? data.billingCity : data.shippingCity}
                                 disabled={data.sameAsBilling} />
                    </FormControl>
                    <FormControl className={classes.formControl} fullWidth>
                      <TextField fullWidth label="Szállítási cím (utca, házszám)" required name="shippingAddress"
                                 onChange={handleChange}
                                 value={data.sameAsBilling ? data.billingAddress : data.shippingAddress}
                                 disabled={data.sameAsBilling}
                      />
                    </FormControl>
                    <FormControl className={classes.formControl} fullWidth>
                      <TextField fullWidth label="Telefonszám vagy mobilszám" required name="shippingPhone" onChange={handleChange}
                                 value={data.sameAsBilling ? data.billingPhone : data.shippingPhone}
                                 disabled={data.sameAsBilling} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel control={
                      <Checkbox
                        checked={termsAccepted}
                        color="primary"
                        onChange={(e) => setTermsAccepted(e.target.checked)}
                        name="terms_accepted"
                      />
                      }
                      label={<div>Az <a href="/adatkezeles" target="_blank">adatkezelési tájékoztatót</a> megismertem, a megadott személyes adataim kezeléséhez hozzájárulok.</div>}
                    />
                    <Typography style={{color: "red"}}>{termsErrorMessage}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel control={
                      <Checkbox
                        checked={data.newsletter}
                        color="primary"
                        onChange={handleChangeCheckBox}
                        name="newsletter"
                      />
                      }
                      label="Szeretnék feliratkozni a hírlevélre."
                    />
                  </Grid>
                </>
            }

            <Grid
              item
              xs={12}
              style={{ textAlign: 'center' }}
              container
              justify={'center'}
            >
              <Button
                variant={'contained'}
                color={'primary'}
                component={'a'}
                href={'/'}
              >
                Vissza
              </Button>
              {
                !success && <Button
                  type="submit"
                  style={{ maxWidth: '500px', margin: 'auto' }}
                  className={classes.regBtn}
                  disabled={disabled}
                >
                  Regisztrációs igény elküldése
                </Button>
              }
            </Grid>
          </Grid>
        </form>
      </Container>
    </div>
  );
};

export default RegistrationPage;
